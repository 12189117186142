import { TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

export interface RowErrorProps {
  columns?: number;
}
const RowError: FC<RowErrorProps> = ({ columns }) => {
  return (
    <TableRow>
      <TableCell colSpan={columns ?? 6}>
        <Typography>Ocorreu um erro ao listar as informações</Typography>
      </TableCell>
    </TableRow>
  );
};

export default RowError;
