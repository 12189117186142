import { IconButton, Stack, TableCell, Tooltip } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';

export interface CellActionsProps {
  id: number;
}

const CellActions: FC<CellActionsProps> = ({ id }) => {
  const goToManage = `/admin/users/participant/${id}`;

  return (
    <TableCell align='right'>
      <Stack direction='row' justifyContent='flex-start'>
        <Tooltip title='Visualizar Participante'>
          <IconButton size='small' component={Link} to={goToManage}>
            <ManageSearchOutlinedIcon color='action' />
          </IconButton>
        </Tooltip>
      </Stack>
    </TableCell>
  );
};

export default CellActions;
