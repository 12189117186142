import { Container, Grid } from '@mui/material';
import './AdmInvited.css';
import DataTable from '../../../components/DataTable';

const AdmInvited = () => {
  return (
    <Container maxWidth='lg'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataTable type='invited' />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdmInvited;
