import { FC } from 'react';
import { IParticipants } from '../../models/models';
import { TableCell, TableRow } from '@mui/material';
import CellActions from './CellActions';

interface RowItemProps {
  data: IParticipants;
}

const RowItem: FC<RowItemProps> = ({ data }) => {
  const { id, name, phone, email, confirmed } = data;

  return (
    <TableRow key={id}>
      <TableCell>{id}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{confirmed ? 'Confirmado' : 'Não confirmado'}</TableCell>
      <CellActions id={id} />
    </TableRow>
  );
};

export default RowItem;
