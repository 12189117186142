import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/');
  };

  return (
    <Grid container spacing={2} sx={{ padding: '80px', textAlign: 'center' }}>
      Página não encontrada
      <Button variant='contained' color='primary' onClick={gotoHome}>
        Voltar à Página Inicial
      </Button>
    </Grid>
  );
};

export default NotFound;
