import { Stack } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface TabPanelProps {
  children: NonNullable<ReactNode>;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Stack direction='column'>{children}</Stack>}
    </div>
  );
};

export default TabPanel;
