import { AppBar, Container, Tab, Tabs } from '@mui/material';
import './Users.css';
import logo from '../../../assets/logoejc.png';
import { useState } from 'react';
import TabPanel from '../../../components/TabPanel/TabPanel';
import ListUsers from '../../../components/ListUsers/ListUsers';

const Users = () => {
  const [value, setValue] = useState(0);
  const handleChange = (_e: unknown, newValue: number) => setValue(newValue);

  return (
    <Container maxWidth='lg' sx={{ textAlign: 'center', minHeight: '90vh' }}>
      <img src={logo} alt='logo' className='logoejc' />
      <br />
      <AppBar position='static'>
        <Tabs value={value} onChange={handleChange} aria-label='users tabs'>
          <Tab label='Encontreiros' />
          <Tab label='Encontristas' />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ListUsers type='inviter' />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListUsers type='invited' />
      </TabPanel>
    </Container>
  );
};

export default Users;
