import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';
import './Loading.css';

interface LoadingProps {
  open: boolean;
}
const Loading: FC<LoadingProps> = ({ open }) => {
  return (
    <Backdrop
      open={open}
      className='dialog'
      aria-labelledby='loading-dialog-title'
      aria-describedby='loading-dialog-description'
    >
      <CircularProgress color='primary' />
    </Backdrop>
  );
};

export default Loading;
