import { Container, Typography } from '@mui/material';
import './Participant.css';
import { useParams } from 'react-router-dom';

const Participant = () => {
  let { id } = useParams();
  return (
    <Container maxWidth='lg'>
      <Typography>Participant: {id}</Typography>
    </Container>
  );
};

export default Participant;
