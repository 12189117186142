import {
  Alert,
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import './Login.css';
import logo from '../../../assets/logoejc.png';
import Loading from '../../../components/Loading/Loading';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { http } from '../../../api/api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('E-mail obrigatório'),
  password: yup.string().required('Campo obrigatório'),
});

const defaultValues = {
  email: '',
  password: '',
};

const Login = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage(false);
  };

  const onSubmit = async (data: IFormInput) => {
    try {
      setLoading(true);
      await http.loginAdmin(data).then((res) => {
        setLoading(false);
        if (res) {
          localStorage.setItem('token', res.access_token);
          navigate('/admin/users');
        }
      });
    } catch (err) {
      setLoading(false);
      setErrorMessage(true);
    }
  };
  return (
    <Container maxWidth='lg' className='containerCenter'>
      <img src={logo} alt='logo' className='logoejc' />
      <br />
      <form className='formBox'>
        <Typography component='h1' variant='h5'>
          Login
        </Typography>
        <br />
        <br />
        <Controller
          name='email'
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              required
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              label='Email'
              value={value}
            />
          )}
        />
        <br />
        <br />
        <Controller
          name='password'
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              fullWidth
              required
              type='password'
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              label='Password'
              value={value}
            />
          )}
        />
        <br />
        <br />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          onClick={handleSubmit(onSubmit)}
        >
          Login
        </Button>
      </form>
      <Loading open={isLoading} />
      <Snackbar
        open={errorMessage}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
          onClose={handleClose}
        >
          Verifique o usuário e senha
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
