import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './InfoCard.css';
import { CardActionArea, CardHeader, Stack } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import soldout from '../../assets/soldout.png';

interface InfoCardProps {
  title: string;
  subheader: string;
  value: string;
  type: string;
  lote: string;
  disable?: boolean;
}
const InfoCard: FC<InfoCardProps> = ({
  title,
  subheader,
  value,
  type,
  lote,
  disable,
}) => {
  const navigate = useNavigate();

  const goToPage = () => {
    if (!disable) {
      if (type === 'inviter') {
        navigate('/encontreiro');
      } else if (type === 'guest') {
        navigate('/encontrista');
      }
    }
  };
  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 285,
        margin: '10px auto',
        position: 'relative',
      }}
    >
      {disable && <img src={soldout} alt='logo' className='soldout' />}
      <CardActionArea
        sx={{
          minHeight: '380px',
        }}
        onClick={goToPage}
        className={disable ? 'cardDisable' : ''}
      >
        <CardHeader title={title} subheader={subheader} />
        <CardContent>
          <Stack direction='row'>
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              className='txtSign'
            >
              R$
            </Typography>
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              className='txtValue'
            >
              {value}
            </Typography>
            <Typography
              gutterBottom
              variant='h5'
              component='div'
              className='txtCents'
            >
              ,00
            </Typography>
          </Stack>
          <Typography gutterBottom component='div' className='txtInfo'>
            Valor do {lote}º lote
          </Typography>
          <Typography
            gutterBottom
            variant='overline'
            component='div'
            className='txtSubmit'
          >
            Inscreva-se
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default InfoCard;
