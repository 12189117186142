export const validarCPF = (cpf: string): boolean => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }

  const resto = soma % 11;
  const digitoVerificador1 = resto < 2 ? 0 : 11 - resto;

  if (parseInt(cpf.charAt(9)) !== digitoVerificador1) return false;

  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }

  const resto2 = soma % 11;
  const digitoVerificador2 = resto2 < 2 ? 0 : 11 - resto2;

  if (parseInt(cpf.charAt(10)) !== digitoVerificador2) return false;

  return true;
};
