import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import RowSkeleton from './RowSkeleton';
import RowError from './RowError';
import RowItem from './RowItem';
import { http } from '../../api/api';
import { IParticipantsOut } from '../../models/models';

export interface DataTableProps {
  type: string;
}

const DataTable: FC<DataTableProps> = ({ type }) => {
  const [page, setPage] = useState<number>(0);
  const [participants, setParticipants] = useState<IParticipantsOut>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    setIsLoading(true);
    const query = {
      page: page + 1,
    };
    http
      .getInviters(query, type)
      .then((data) => {
        setParticipants(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
      });
    return () => {};
  }, [page, type]);

  const renderBody = () => {
    if (isLoading) {
      return <RowSkeleton />;
    }

    if (isError) {
      return <RowError />;
    }

    return (
      <>
        {participants?.data.map((row) => (
          <RowItem key={row.id} data={row} />
        ))}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderBody()}</TableBody>
      </Table>
      {participants && (
        <TablePagination
          component='div'
          count={participants?.pagination.total ?? 0}
          page={page}
          rowsPerPageOptions={[15]}
          rowsPerPage={15}
          onPageChange={handlePageChange}
          showFirstButton
          showLastButton
        />
      )}
    </TableContainer>
  );
};

export default DataTable;
