import { Box, Grid, Stack, Typography } from '@mui/material';
import './Home.css';
import InfoCard from '../../components/InfoCard/InfoCard';
import logo from '../../assets/logoejc.png';

const Home = () => {
  return (
    <Grid
      container
      spacing={2}
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Grid container className='containerBox'>
        <Box sx={{ width: '100%', height: 500 }}>
          <img src={logo} alt='logo' className='logo' />
          <Stack className='contVerse'>
            <Typography variant='overline' gutterBottom>
              Eu vos escrevi, jovens, porque sois fortes, e a palavra de Deus
              está em vós, e já vencestes o inimigo.
            </Typography>
            <Typography variant='overline' gutterBottom>
              1 João 2:14
            </Typography>
          </Stack>
        </Box>
        <InfoCard
          title='INSCRIÇÃO ENCONTREIRO'
          subheader='Para quem já participou do EJC'
          value='180'
          type='inviter'
          lote='3'
          disable
        />

        <InfoCard
          title='INSCRIÇÃO ENCONTRISTA'
          subheader='Para quem vai participar pela primeira vez no EJC'
          value='330'
          type='guest'
          lote='3'
          disable
        />
      </Grid>
    </Grid>
  );
};

export default Home;
