import { Skeleton, TableCell, TableRow } from '@mui/material';

const RowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='circular' height={24} width={24} />
      </TableCell>
    </TableRow>
  );
};

export default RowSkeleton;
