import { Container, Grid } from '@mui/material';
import './AdmInviter.css';
import DataTable from '../../../components/DataTable';

const AdmInviter = () => {
  return (
    <Container maxWidth='lg'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataTable type='inviter' />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdmInviter;
