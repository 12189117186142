import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import MenuBar from './components/MenuBar/MenuBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Inviter from './pages/Inviter/Inviter';
import NotFound from './pages/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import DocsInviter from './pages/DocsInviter/DocsInviter';
import Confirm from './pages/Confirm/Confirm';
import DocsGuest from './pages/DocsGuest/DocsGuest';
import Login from './pages/Admin/Login/Login';
import Users from './pages/Admin/Users/Users';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Participant from './pages/Admin/Participant/Participant';
import Guest from './pages/Guest/Guest';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <MenuBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/encontreiro' element={<Inviter />} />
          <Route
            path='/docsencontreiro/:registrationCode'
            element={<DocsInviter />}
          />
          <Route
            path='/docsencontrista/:registrationCode'
            element={<DocsGuest />}
          />
          {/* <Route path='/encontrista' element={<Guest />} /> */}
          <Route path='/confirm' element={<Confirm />} />
          <Route path='/admin' element={<Login />} />
          <Route
            path='/admin/users'
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/users/participant/:id'
            element={
              <ProtectedRoute>
                <Participant />
              </ProtectedRoute>
            }
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;
