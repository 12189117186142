import axios, { AxiosResponse } from 'axios';
import {
  IInviterOut,
  ICreateInviter,
  ICreateInvited,
  IInvitedOut,
  ILogin,
  ILoginOut,
  IGetParams,
  IParticipantsOut,
} from '../models/models';

const instance = axios.create({
  baseURL: 'https://rjc-2024-imperatriz-c8f2bdae68a9.herokuapp.com/',
  timeout: 20000,
});

const responseBody = (response: AxiosResponse) => response.data;
const token = localStorage.getItem('token');

const requests = {
  get: (url: string, params: IGetParams) =>
    instance
      .get(url, {
        params,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),

  postDocs: (url: string, body: {}) =>
    instance
      .post(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(responseBody),

  postLogin: (url: string, body: {}) =>
    instance.post(url, body).then(responseBody),
};

export const http = {
  addInviter: (post: ICreateInviter): Promise<IInviterOut> =>
    requests.post('/register/inviter', post),

  addInvited: (post: ICreateInvited): Promise<IInvitedOut> =>
    requests.post('/register/invited', post),

  sendConfirm: (post: any): Promise<Response> =>
    requests.postDocs('/register/document', post),

  loginAdmin: (post: ILogin): Promise<ILoginOut> =>
    requests.postLogin('/auth/login', post),

  getInviters: (params: IGetParams, type: string): Promise<IParticipantsOut> =>
    requests.get(`/participant/list/${type}`, params),
};
