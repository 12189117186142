import { Grid, Stack, Typography } from '@mui/material';
import './Footer.css';

const Footer = () => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      className='footer'
    >
      <Grid className='containerBox'>
        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
        >
          <Typography gutterBottom variant='overline' component='div'>
            EJC - Imperatriz
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
