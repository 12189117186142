import { Button, Container, Typography } from '@mui/material';
import './Confirm.css';
import logo from '../../assets/logoejc.png';
import confirmed from '../../assets/confirmed.png';
import { useNavigate } from 'react-router-dom';

const Confirm = () => {
  const navigate = useNavigate();
  const gotoHome = () => {
    navigate('/');
  };
  return (
    <Container maxWidth='lg' sx={{ textAlign: 'center', height: '90vh' }}>
      <img src={logo} alt='logo' className='logoejc' />
      <br />
      <img src={confirmed} alt='confirmed' className='logoConfirmed' />

      <Typography variant='h4' gutterBottom>
        Inscrição realizada com sucesso!!!
      </Typography>
      <Typography variant='body1' paragraph>
        Guarde as informações
      </Typography>
      <Typography variant='body1' paragraph>
        Nosso programa ocorrerá nos dias 02 a 04 de Agosto 2024
        <br />
        <b>Local:</b> SEST SENAT
      </Typography>
      <Typography variant='body1' paragraph>
        Aguardamos ansiosamente por nosso encontro.
      </Typography>
      <Button variant='contained' color='primary' onClick={gotoHome}>
        Voltar à Página Inicial
      </Button>
    </Container>
  );
};

export default Confirm;
