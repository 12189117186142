import './ListUsers.css';
import { Container } from '@mui/material';
import { FC } from 'react';
import AdmInviter from '../../pages/Admin/AdmInviter/AdmInviter';
import AdmInvited from '../../pages/Admin/AdmInvited/AdmInvited';

interface ListUsersProps {
  type: string;
}
const ListUsers: FC<ListUsersProps> = ({ type }) => {
  return (
    <Container maxWidth='lg'>
      {type === 'inviter' && <AdmInviter />}
      {type === 'invited' && <AdmInvited />}
    </Container>
  );
};

export default ListUsers;
