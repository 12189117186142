import {
  Alert,
  Button,
  Container,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './Inviter.css';
import { useNavigate } from 'react-router-dom';
import {
  CepInputCustom,
  CpfInputCustom,
  PhoneInputCustom,
} from '../../services/inputcustoms';
import { useState } from 'react';
import { http } from '../../api/api';
import Loading from '../../components/Loading/Loading';
import { validarCPF } from '../../services/validarCPF';

interface IFormInput {
  name: string;
  email: string;
  cpf: string;
  address: string;
  district: string;
  zipcode: string;
  phone: string;
  birthday: Date;
  gender: string;
  motherName: string;
  fatherName: string;
  religion: string;
  church: string;
  smallGroup: string;
  timesToWork: number;
  teamsToWork: string;
  teamsLikeWork: string;
  foodRestriction: string;
}

const schema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('E-mail obrigatório'),
  cpf: yup
    .string()
    .required('CPF obrigatório')
    .test('is-valid-cpf', 'Digite um CPF Valido', (value) =>
      value ? validarCPF(value) : true
    ),
  address: yup.string().required('Endereço obrigatório'),
  district: yup.string().required('Bairro obrigatório'),
  zipcode: yup.string().required('CEP obrigatório'),
  phone: yup.string().required('Telefone Celular obrigatório'),
  birthday: yup.date().required('Data de Nascimento obrigatória'),
  gender: yup.string().required('Campo obrigatório'),
  motherName: yup.string().required('Nome da Mãe obrigatório'),
  fatherName: yup.string().required('Nome do Pai obrigatório'),
  religion: yup.string().required('Campo obrigatório'),
  church: yup.string().required('Campo obrigatório'),
  smallGroup: yup.string().required('Campo obrigatório'),
  timesToWork: yup.number().required('Campo obrigatório'),
  teamsToWork: yup.string().required('Campo obrigatório'),
  teamsLikeWork: yup.string().required('Campo obrigatório'),
  foodRestriction: yup.string().required('Campo obrigatório'),
});

const defaultValues = {
  name: '',
  email: '',
  cpf: '',
  address: '',
  district: '',
  zipcode: '',
  phone: '',
  birthday: new Date(),
  gender: '',
  motherName: '',
  fatherName: '',
  religion: '',
  church: '',
  smallGroup: '',
  timesToWork: 0,
  teamsToWork: '',
  teamsLikeWork: '',
  foodRestriction: '',
};

const Inviter = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage(false);
  };

  const setErrorsApi = (errorsItems: any) => {
    const newErrors = {} as any;
    Object.keys(errorsItems).forEach((key) => {
      const inputReg = control.getFieldState(key as any);
      if (inputReg) {
        newErrors[key] = errorsItems[key][0];
      }
    });
    Object.keys(newErrors).forEach((item) => {
      control.setError(item as any, {
        type: 'server',
        message: newErrors[item],
      });
    });
  };

  const onSubmit = async (data: IFormInput) => {
    const sanitizeCpf = data.cpf.replace(/[^0-9]/g, '');
    const sanitizeZipCode = data.zipcode.replace(/[^0-9]/g, '');
    const dateBirth = data.birthday.toISOString().slice(0, 10);

    const payload = {
      ...data,
      cpf: sanitizeCpf,
      zipcode: sanitizeZipCode,
      birthday: dateBirth,
    };

    try {
      setLoading(true);
      await http.addInviter(payload).then((res) => {
        setLoading(false);

        navigate(`/docsencontreiro/${res.data.registrationCode}`);
      });
    } catch (err) {
      setLoading(false);
      const { response } = err as any;
      const listErrors = response?.data?.errors;
      if (listErrors) {
        setErrorsApi(listErrors);
      } else {
        setErrorMessage(true);
      }
    }
  };

  return (
    <Container maxWidth='lg'>
      <form autoComplete='off' className='formBox'>
        <Typography gutterBottom variant='h3' component='div'>
          Olá Encontreiro!
        </Typography>
        <Typography gutterBottom variant='h5' component='div'>
          Aqui é o seu lugar.
        </Typography>
        <Typography gutterBottom component='div'>
          Preencha todos os campos.
        </Typography>
        <Grid container className='containerForm' spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  fullWidth
                  required
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  label='Nome Completo'
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.email)}
                  fullWidth
                  type='email'
                  helperText={errors.email?.message}
                  label='Seu melhor e-mail'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              name='cpf'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.cpf)}
                  fullWidth
                  helperText={errors.cpf?.message}
                  label='CPF'
                  required
                  value={value}
                  InputProps={{
                    inputComponent: CpfInputCustom as any,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='address'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  fullWidth
                  required
                  error={Boolean(errors.address)}
                  helperText={errors.address?.message}
                  label='Endereço Completo'
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <Controller
              name='district'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.district)}
                  fullWidth
                  helperText={errors.district?.message}
                  label='Bairro'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              name='zipcode'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.zipcode)}
                  fullWidth
                  helperText={errors.zipcode?.message}
                  label='CEP'
                  required
                  value={value}
                  InputProps={{
                    inputComponent: CepInputCustom as any,
                  }}
                ></TextField>
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              name='phone'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.phone)}
                  fullWidth
                  helperText={errors.phone?.message}
                  label='Telefone Celular'
                  required
                  value={value}
                  InputProps={{
                    inputComponent: PhoneInputCustom as any,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              name='birthday'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.birthday)}
                  fullWidth
                  helperText={errors.birthday?.message}
                  label='Data de Nascimento'
                  required
                  type='Date'
                  value={value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              name='gender'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.gender)}
                  fullWidth
                  helperText={errors.gender?.message}
                  label='Gênero'
                  required
                  value={value}
                  defaultValue=''
                  select
                >
                  <MenuItem value={'m'}>Masculino</MenuItem>
                  <MenuItem value={'f'}>Feminino</MenuItem>
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='motherName'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  fullWidth
                  required
                  error={Boolean(errors.motherName)}
                  helperText={errors.motherName?.message}
                  label='Nome da Mãe'
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='fatherName'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  fullWidth
                  required
                  error={Boolean(errors.fatherName)}
                  helperText={errors.fatherName?.message}
                  label='Nome do Pai'
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name='religion'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.religion)}
                  fullWidth
                  helperText={errors.religion?.message}
                  label='Qual a sua religião?'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name='church'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.church)}
                  fullWidth
                  helperText={errors.church?.message}
                  label='Igreja que frequenta?'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name='smallGroup'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.smallGroup)}
                  fullWidth
                  helperText={errors.smallGroup?.message}
                  label='Qual pequeno grupo esta frequentando?'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name='timesToWork'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.timesToWork)}
                  fullWidth
                  type='number'
                  helperText={errors.timesToWork?.message}
                  label='Quantas vezes já trabalhou no EJC?'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name='teamsToWork'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.teamsToWork)}
                  fullWidth
                  helperText={errors.teamsToWork?.message}
                  label='Em quais equipes já trabalhou no RJC?'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name='teamsLikeWork'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.teamsLikeWork)}
                  fullWidth
                  helperText={errors.teamsLikeWork?.message}
                  label='Cite três equipes que gostaria de trabalhar'
                  required
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='foodRestriction'
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  error={Boolean(errors.foodRestriction)}
                  fullWidth
                  helperText={errors.foodRestriction?.message}
                  label='Você tem alguma restrição alimentar? Qual?'
                  required
                  value={value}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 200 }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='contained'
              type='submit'
              onClick={handleSubmit(onSubmit)}
              sx={{ float: 'right' }}
            >
              Enviar dados
            </Button>
          </Grid>
        </Grid>
      </form>
      <Loading open={isLoading} />
      <Snackbar
        open={errorMessage}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
          onClose={handleClose}
        >
          Ocorreu um erro ao enviar os dados.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Inviter;
