import {
  Alert,
  Button,
  Container,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import './DocsInviter.css';
import InputFileUpload from '../../components/InputFileUpload/InputFileUpload';
import qrcode from '../../assets/qrcode.jpeg';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { http } from '../../api/api';
import Loading from '../../components/Loading/Loading';

interface IFormInput {
  docImage: any;
  pixImage: any;
  letterImage: any;
}

const DocsInviter = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<IFormInput>();
  const [copied, setCopied] = useState(false);

  const { registrationCode } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorMessage(false);
  };

  const onSubmit = async (data: IFormInput) => {
    const formData = new FormData();
    if (data.docImage) {
      formData.append('docImage', data.docImage[0]);
    }
    if (data.pixImage) {
      formData.append('pixImage', data.pixImage[0]);
    }
    if (data.letterImage) {
      formData.append('letterImage', data.letterImage[0]);
    }
    if (registrationCode) {
      formData.append('registrationCode', registrationCode);
    }

    try {
      setLoading(true);
      await http.sendConfirm(formData).then((res) => {
        setLoading(false);

        navigate('/confirm');
      });
    } catch (err) {
      setLoading(false);
      setErrorMessage(true);
    }
  };

  const textToCopy =
    '00020101021126430014br.gov.bcb.pix0121liusferdnan@gmail.com5204000053039865802BR5917LUIS F DE O GOMES6010IMPERATRIZ62070503***6304A9BB';

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);

        setTimeout(() => {
          setCopied(false);
        }, 800); //
      })
      .catch((error) => {
        console.error(
          'Erro ao copiar o texto para a área de transferência:',
          error
        );
      });
  };

  return (
    <Container maxWidth='lg'>
      <form autoComplete='off' className='formBox'>
        <Stack className='boxTxt'>
          <Typography gutterBottom variant='h3' component='div'>
            Olá Encontreiro!
          </Typography>
          <Typography gutterBottom variant='h5' component='div'>
            Obrigado por nos fornecer as informações.
          </Typography>
        </Stack>
        <Divider />
        <Stack className='boxTxt'>
          <Typography gutterBottom component='div'>
            Primeiro precisamos que faça o PIX do valor da inscrição de
            Encontreiro.
          </Typography>
          <Typography gutterBottom component='div'>
            O terceiro lote está no valor de R$ 180,00.
          </Typography>
        </Stack>
        <Stack className='boxTxt'>
          <Typography gutterBottom variant='h6' component='div'>
            Opções para enviar o PIX
          </Typography>
        </Stack>
        <Stack className='boxTxt'>
          <Typography gutterBottom variant='h6' component='div'>
            Copia e cola - clique para copiar
          </Typography>
          <Typography
            gutterBottom
            variant='body1'
            component='div'
            onClick={copyToClipboard}
            className='copyText'
            style={{
              color: copied ? 'green' : 'inherit',
              transition: 'color 0.5s',
            }}
          >
            00020101021126430014br.gov.bcb.pix0121liusferdnan@gmail.com5204000053039865802BR5917LUIS
            F DE O GOMES6010IMPERATRIZ62070503***6304A9BB
          </Typography>
        </Stack>
        <Stack className='boxTxt'>
          <Typography gutterBottom variant='h6' component='div'>
            Chave PIX
          </Typography>
          <Typography gutterBottom variant='body1' component='div'>
            liusferdnan@gmail.com
          </Typography>
        </Stack>
        <Stack className='boxTxt'>
          <Typography gutterBottom variant='h6' component='div'>
            QRCODE
          </Typography>
          <img src={qrcode} alt='qrcode' className='qrcode' />
        </Stack>
        <Stack className='boxTxt'>
          <Typography gutterBottom variant='h6' component='div'>
            Agora precisamos de alguns anexos.
          </Typography>
        </Stack>

        <Grid container className='containerForm' spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom component='div'>
              Enviar imagem do RG ou hablitação.
            </Typography>
            <Controller
              name='docImage'
              control={control}
              render={({ field }) => (
                <InputFileUpload name='docImage' control={control} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom component='div'>
              Enviar comprovante do PIX do valor da inscrição
            </Typography>
            <Controller
              name='pixImage'
              control={control}
              render={({ field }) => (
                <InputFileUpload name='pixImage' control={control} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom component='div'>
              Enviar carta de recomendação. OBS. Apenas para equipes que
              necessitam da carta de recomendação.
            </Typography>
            <Controller
              name='letterImage'
              control={control}
              render={({ field }) => (
                <InputFileUpload name='letterImage' control={control} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='contained'
              onClick={handleSubmit(onSubmit)}
              sx={{ float: 'right' }}
            >
              Enviar dados
            </Button>
          </Grid>
        </Grid>
      </form>
      <Loading open={isLoading} />
      <Snackbar
        open={errorMessage}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert
          severity='error'
          variant='filled'
          sx={{ width: '100%' }}
          onClose={handleClose}
        >
          Ocorreu um erro ao enviar os dados.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default DocsInviter;
